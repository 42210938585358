import CampaignList from '../../../components/campaign/list/campaignList';

export default {
  name: 'WhatsAppCampaignList',
  data() {
    return {
      campaignChannelType: 'whatsApp',
      campaignChannel: 'WhatsApp'
    };
  },
  components: {
    CampaignList
  },
  methods: {},
  mounted() {}
};
